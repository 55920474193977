import { KinshipPetProfile, CustomField, PracticeGroup, VetClinic, PracticeGroupDivisions } from "../../utils/kinshipTypes";
import { getPracticeGroups, getLinnaeusPracticeGroup, LINNAEUS, putPracticeGroup, clearPracticeGroup, postPracticeGroup } from "../../utils/back4AppApi";
import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { translate } from "../../utils/localisation";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  IconButton,
  Box,
  Typography,
  MenuItem,
  Select,
  Stack,
  Grid,
  Divider,
} from "@mui/material";

interface Props {
  customFields?: CustomField[];
  pet?: KinshipPetProfile;
  onAction?: Function;
}

export function PracticeGroups({ customFields, pet, onAction = () => {} }: Props) {
  const [selectedPracticeGroup, setSelectedPracticeGroup] = useState<VetClinic | undefined>();
  const [practiceGroups, setPracticeGroup] = useState<VetClinic[]>([]);
  const [newPracticeGroupID, setNewPracticeGroupID] = useState("");

  const onPracticeGroupAction = async (event: any, practiceGroupID: string, ...args: Array<string | null>) => {
    if(practiceGroupID) {
      const actionType = args[0];
      const actionHasConfirmDialogStep = args[1];
      const practiceGroupArg = practiceGroups.find((group) => group.kinshipId === practiceGroupID);
      let data: PracticeGroupDivisions = {};
  
      if (actionHasConfirmDialogStep === "NEEDS_CONFIRMATION") {
        // eslint-disable-next-line no-restricted-globals
        if (confirm(translate('are_you_sure_remove_practice_group'))) {
          onPracticeGroupAction(event, practiceGroupID, actionType);
        }
      }
      else if(actionType === "SET") {
        if (!practiceGroupArg) {
          console.warn("No matching practice group found for ID:", practiceGroupID);
          return;
        }
        
        data = {
          division: practiceGroupArg.urlCode,
          kinshipId: practiceGroupArg.kinshipId,
          partnerCode: (getLinnaeusPracticeGroup(pet?.ownersProfileExtras) as unknown as PracticeGroup)?.partnerCode || LINNAEUS,
          promoCode: pet?.ownersProfileExtras?.divisions?.[0]?.promoCode || null,
          subDivisions: []
        };

        if(selectedPracticeGroup) {
          data.id = pet?.ownersProfileExtras?.divisions?.[0]?.id || "";
          console.log("putPracticeGroup->data =>", data, pet?.ownersProfile);
          await putPracticeGroup(pet?.ownersProfile, data);
        } else {
          console.log("postPracticeGroup->data =>", data, pet?.ownersProfile);
          await postPracticeGroup(pet?.ownersProfile, data);
        }
      } else if (actionType === "CLEAR") {
        
        data = {
          id: pet?.ownersProfileExtras?.divisions?.[0]?.id || "",
        };
        
        console.log("clearPracticeGroup->data =>", data, pet?.ownersProfile);
        await clearPracticeGroup(pet?.ownersProfile, data);
      }
      
      onAction(data);
    }
  };

  useEffect(() => {
    const fetchPracticeGroup = async () => {
      const partnerInfo = getLinnaeusPracticeGroup(pet?.ownersProfileExtras) as PracticeGroup;
      console.log("PracticeGroups->partnerInfo =>", partnerInfo, 
        "partnerCode =>", partnerInfo?.partnerCode
      );

      // const data  = await getPracticeGroups(
      //   partnerInfo?.partnerCode || '',
      //   partnerInfo?.kinshipId || ''
      // );

      const data  = await getPracticeGroups(
        '',
        ''
      );

      const practiceGroupsData = data.data as Array<VetClinic>;
      setPracticeGroup(practiceGroupsData);

      console.log("practiceGroupsData =>", practiceGroupsData);

      if (pet?.ownersProfileExtras?.divisions && pet.ownersProfileExtras.divisions.length > 0) {
        const alreadySelectedPracticeGroup = practiceGroupsData?.find(
          (practiceGroup) => practiceGroup.kinshipId === (pet?.ownersProfileExtras?.divisions ? pet?.ownersProfileExtras?.divisions[0]?.kinshipId : "")
        );
      
        console.log("alreadySelectedPracticeGroup =>", alreadySelectedPracticeGroup);
      
        if (alreadySelectedPracticeGroup) {
          setSelectedPracticeGroup(alreadySelectedPracticeGroup);
        }
      }
      
    }

    fetchPracticeGroup();
  }, [customFields, newPracticeGroupID, pet?.ownersProfileExtras]);

  const PracticeGroupRender = practiceGroups.map((menuItem) => {
    return (
      <MenuItem
        key={`${menuItem.name} - ${menuItem.kinshipId}`}
        value={menuItem.kinshipId}
        sx={{ whiteSpace: "normal", display: "block", py: 2 }} // Ensures multi-line support
      >
        <Typography variant="subtitle1" fontWeight="bold">
          {menuItem?.name || ""}
        </Typography>
  
        {menuItem.practices.map((practice, index) => (
          <Box key={practice.name} sx={{ mt: index === 0 ? 1 : 2 }}>
            <Typography variant="body2">{practice.name}</Typography>
            <Typography variant="body2">{practice.phone}</Typography>
            <Typography variant="body2">{practice.email}</Typography>
            <Typography variant="body2">{practice.address}</Typography>
            {index !== menuItem.practices.length - 1 && <Divider sx={{ my: 1 }} />} 
          </Box>
        ))}
      </MenuItem>
    );
  });

  const mappedPracticeGroup = () => {
    if (selectedPracticeGroup) {
      return (
        <TableRow>
          <TableCell align="left" colSpan={2}>
            <Typography variant="subtitle1" fontWeight="bold">
              {selectedPracticeGroup.name}
            </Typography>
  
            {selectedPracticeGroup.practices.map((practice, index) => (
              <Box key={practice.name} sx={{ mt: index === 0 ? 1 : 2 }}>
                <Typography variant="body2">{practice.name}</Typography>
                <Typography variant="body2">{practice.phone}</Typography>
                <Typography variant="body2">{practice.email}</Typography>
                <Typography variant="body2">{practice.address}</Typography>
                {index !== selectedPracticeGroup.practices.length - 1 && <Divider sx={{ my: 1 }} />} 
              </Box>
            ))}
          </TableCell>
  
          <TableCell align="left" colSpan={2}>
            <Stack direction="row" spacing={1}>
              <IconButton
                color="primary"
                aria-label={translate("delete")}
                onClick={(event) =>
                  onPracticeGroupAction(event, newPracticeGroupID, "CLEAR", "NEEDS_CONFIRMATION")
                }
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          </TableCell>
        </TableRow>
      );
    }
  };
  

  return (
    <Box sx={{ mt: 2, p: 4, border: 1, borderColor: "gray", borderRadius: 5 }}>
    <Grid container sx={{ minWidth: 1200 }}>
      <Grid item xs={6}>
        <Typography
        variant="h4"
        component="h4"
        sx={{ mb: 2, fontWeight: "medium" }}
      >
        {translate("practiceGroups")}
      </Typography>
      <Table sx={{width: "max-content"}}>
      <TableBody>
        {mappedPracticeGroup()}
        <TableRow>
            <TableCell align="left" colSpan={2}>
              <Select
                displayEmpty
                value={newPracticeGroupID || selectedPracticeGroup?.kinshipId || ""}
                onChange={(e) => {
                  console.log("select->newPracticeGroupID =>", e.target.value);
                  setNewPracticeGroupID(e.target.value);
                }}
                sx={{ 
                  maxHeight: 500,
                  width: 'auto',
                  whiteSpace: 'normal'
                }}
              >
                {PracticeGroupRender}
              </Select>
            </TableCell>
            <TableCell align="left" colSpan={2}>
              <Stack direction="row" spacing={1}>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={(event) => onPracticeGroupAction(event, newPracticeGroupID, "SET")}
                >
                  {translate('set_practice_group')}
                </Button>
              </Stack>
            </TableCell>
        </TableRow>
      </TableBody>
    </Table>
    </Grid>
  </Grid>
</Box>
);
}
