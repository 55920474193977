import { useState, useEffect } from "react";
import { useFilePicker } from 'use-file-picker';
import {
  updatePet,
  updateDocumentVetName,
  getMedicalRecordRequets,
  updateMedicalRecordRequestStatus
} from "../../utils/back4AppApi";
import { uploadDocument } from "../../utils/kinshipApi";
import {
  KinshipPetProfile,
  MedicalRecord,
  MedicalRecordRequest
} from "../../utils/kinshipTypes";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  Button,
  Alert,
  Box,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import { translate } from "../../utils/localisation";
import { StyledButton } from '../../styles/buttons';
import { Vaccinations } from "./Vaccinations";
import { Activities } from "./Activities";
import { PracticeLocations } from "./PracticeLocations";
import { Allergies } from "./Allergies";
import { Foods } from "./Foods";
import { PracticeGroups } from "./PracticeGroups";
import { isUKOwnershipLocale } from "../../utils/apis";

interface Props {
  pet?: KinshipPetProfile;
  onActionVaccination?: Function;
}

export function MedicalRecordView({ 
  pet, 
  onActionVaccination = () => {}, 
}: Props) {
  const [record, setRecord] = useState<MedicalRecord>();
  const [weight, setWeight] = useState(pet?.weight || "");
  const [microchipId, setMicrochipId] = useState("");
  const [microchipManufacturer, setMicrochipManufacturer] = useState("");
  const [petUpdateResult, setPetUpdateResult] = useState("");
  const [vaccineRecency, setVaccineRecency] = useState("");
  const [fileUpload, setFileUpload] = useState<File>();
  const [recordRequests, setRecordRequests] = useState<
    Array<MedicalRecordRequest>
  >([]);
  const [vetName, setVetName] = useState("");
  const { openFilePicker, loading, errors, plainFiles} = useFilePicker({
    readAs: 'BinaryString',
    accept: 'application/pdf',
    multiple: true,
    onFilesSuccessfullySelected: async ({ plainFiles: plainFilesProp }) => {
      if (!plainFilesProp) {
        return;
      }
      if (!plainFilesProp[0].name.toLowerCase().endsWith('.pdf')) {
        alert('File must be a PDF');
        return;
      }
      setFileUpload(plainFilesProp[0]);
    },
  });

  useEffect(() => {
    setRecord(pet?.medicalRecord);
    setWeight(pet?.weight || "");
    setMicrochipId(pet?.microchipId || "");
    setVaccineRecency(pet?.vaccineRecency || "");
    getMedicalRecordRequets(pet?.kinshipPetId).then((res: any) => {
      setRecordRequests(res.data);
    });
  }, [pet]);

  const onSubmit = (event: any) => {
    event.preventDefault();
    updatePet(pet?.kinshipPetId, weight, microchipId, microchipManufacturer, vaccineRecency);
    setPetUpdateResult("Success");
  };

  const onFileUpload = async () => {
    if (!fileUpload) {
      console.error('no file:', fileUpload);
      return;
    }
    const formData = new FormData();
    formData.append('file', fileUpload, fileUpload.name);
    try {
      const resp = await uploadDocument(pet?.kinshipPetId, formData);
      await updateDocumentVetName(resp?.data?.documentURL, vetName);
      onActionVaccination();
    } catch (error) {
      console.error('Error uploading file:', error, errors);
    }
  };

  const onMRRStatusChange = async (reqId: string, status: string) => {
    await updateMedicalRecordRequestStatus(reqId, status);
    getMedicalRecordRequets(pet?.kinshipPetId).then((res: any) => {
      setRecordRequests(res.data);
    });
  };

  useEffect(() => {
    const processData = async () => {
        if (!loading && plainFiles?.length > 0 && !plainFiles) {
        setFileUpload(plainFiles[0]);
      }
    };
    processData();
  }, [loading, plainFiles]);

  return (
    <form onSubmit={onSubmit}>
      <Box sx={{ mt: 2, p: 4, border: 1, borderColor: "gray", borderRadius: 5, minWidth: 1200 }}>
        <Typography
          variant="h4"
          component="h4"
          sx={{ mb: 2, fontWeight: "medium" }}
        >
          {translate('pet-medical-records-selected-screen.medical-record.title-text')}
        </Typography>
        <div>
          <TextField
            size="small"
            id="weight"
            name="weight"
            label={translate('pet-medical-records-selected-screen.medical-record.weight-input-text')}
            variant="outlined"
            value={weight}
            onChange={(e) => {
              setPetUpdateResult("");
              setWeight(e.target.value);
            }}
            margin="normal"
          />
        </div>
        <div>
          <TextField
            size="small"
            id="microchipId"
            name="microchipId"
            label={translate('microchip_id')}
            variant="outlined"
            value={microchipId}
            margin="normal"
            onChange={(e) => {
              setPetUpdateResult("");
              setMicrochipId(e.target.value);
            }}
          />
        </div>
        <div>
          <TextField
            size="small"
            id="microchipManufacturer"
            name="microchipManufacturer"
            label={translate('microchip_manufacturer')}
            variant="outlined"
            value={microchipManufacturer}
            margin="normal"
            onChange={(e) => {
              setPetUpdateResult("");
              setMicrochipManufacturer(e.target.value);
            }}
          />
        </div>
        <div>
          <TextField
            size="small"
            id="vaccineRecency"
            name="vaccineRecency"
            label={translate('vaccine_recency')}
            variant="outlined"
            value={vaccineRecency}
            margin="normal"
            onChange={(e) => {
              setPetUpdateResult("");
              setVaccineRecency(e.target.value);
            }}
          />
        </div>
        <div>
          <Button type="submit" variant="contained">
            {translate('save_uppercase')}
          </Button>
          {!!petUpdateResult && (
            <Alert sx={{ width: 300, mt: 2 }} severity="success">
              {translate('pet-medical-records-selected-screen.medical-record.save-alert-text')}
            </Alert>
          )}
        </div>
      </Box>
      {isUKOwnershipLocale(pet?.ownershipLocale || "") && (
        <>
          <PracticeGroups customFields={pet?.customFields} pet={pet} onAction={onActionVaccination} />
          <PracticeLocations customFields={pet?.customFields} pet={pet} onAction={onActionVaccination} />
        </>
      )}
      <Activities customFields={pet?.customFields} pet={pet} onAction={onActionVaccination} />
      <Allergies record={record} pet={pet} onAction={onActionVaccination} />
      <Foods pet={pet} onAction={onActionVaccination} />
      <Vaccinations record={record} pet={pet} onActionVaccination={onActionVaccination} />
      <Box sx={{ mt: 2, p: 4, border: 1, borderColor: "gray", borderRadius: 5 }}>
        <Typography
          variant="h4"
          component="h4"
          sx={{ mb: 2, fontWeight: "medium" }}
        >
          {translate('pet-medical-records-selected-screen.documents.title-text')}
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{translate('pet-medical-records-selected-screen.documents.docs-table-col-1-title-text')}</TableCell>
              <TableCell>{translate('vet_name')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pet?.documents?.vet_records?.map((doc: any, index: number) => {
              return (
                <TableRow key={index}>
                  <TableCell>{doc?.documentURL?.split("/")?.pop()}</TableCell>
                  <TableCell>{doc?.metadata?.["vet-name"]}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Typography
          variant="h5"
          component="h5"
          sx={{ my: 2, fontWeight: "medium" }}
        >
          {translate('pet-medical-records-selected-screen.documents.subtitle-text')}
        </Typography>
        <StyledButton 
          variant="contained" 
          onClick={() => openFilePicker()} 
          sx={{ mt: 2, display: "block" }}
        >
          {translate('pet-medical-records-selected-screen.documents.cta-button-select-text')}
        </StyledButton>
        <TextField
          size="small"
          label={translate('new_name')}
          variant="outlined"
          value={vetName}
          onChange={(e) => setVetName(e.target.value)}
          margin="normal"
          sx={{ display: "block" }}
        />
        <p>{
          translate([
            'pet-medical-records-selected-screen.documents.filename-label-text', { 
              var: `${fileUpload?.name}`
          }])
        }</p>
        <Button
          variant="contained"
          onClick={onFileUpload}
          sx={{ mt: 2, display: "block" }}
        >
          {translate('pet-medical-records-selected-screen.documents.cta-button-upload-text')}!
        </Button>
      </Box>
      <Box
        sx={{ mt: 2, p: 4, border: 1, borderColor: "gray", borderRadius: 5 }}
      >
        <Typography
          variant="h4"
          component="h4"
          sx={{ mb: 2, fontWeight: "medium" }}
        >
          {translate('pet-medical-records-selected-screen.medical-record-request.title-text')}
        </Typography>
        {!recordRequests.length ? (
          <p>{translate('pet-medical-records-selected-screen.medical-record-request.none-text')}</p>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">{translate('pet-medical-records-selected-screen.medical-record-request.docs-table-col-1-title-text')}</TableCell>
                <TableCell>{translate('request_date')}</TableCell>
                <TableCell>{translate('pet-medical-records-selected-screen.medical-record-request.docs-table-col-3-title-text')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recordRequests?.map(
                (req: MedicalRecordRequest, index: number) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{req?.business_name}</TableCell>
                      <TableCell>
                        {new Date(
                          req?.created_timestamp
                        )?.toLocaleDateString()}
                      </TableCell>
                      <TableCell>
                        <Select
                          value={req?.status}
                          onChange={(e) =>
                            onMRRStatusChange(req?.id, e.target.value)
                          }
                        >
                          {[
                            "requested",
                            "pending",
                            "pet-parent-cancelled",
                            "complete",
                            "rejected",
                            "no-vax-on-file",
                            "additional-info-needed",
                          ].map((val) => (
                            <MenuItem key={val} value={val}>
                              {val}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        )}
      </Box>
    </form>
  );
}

