import { KinshipPetProfile, CustomField, PracticeGroup, PracticeLocation } from "../../utils/kinshipTypes";
import { getVeterinarySpecialists, getLinnaeusPracticeGroup, updatePetProfile } from "../../utils/back4AppApi";
import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { translate } from "../../utils/localisation";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  IconButton,
  Box,
  Typography,
  MenuItem,
  Select,
  Stack,
  Grid,
  Divider,
} from "@mui/material";

interface Props {
  customFields?: CustomField[];
  pet?: KinshipPetProfile;
  onAction?: Function;
}

export function PracticeLocations({ customFields, pet, onAction = () => {} }: Props) {
  const [selectedPracticeLocation, setSelectedPracticeLocation] = useState<PracticeLocation | undefined>();
  const [practiceLocation, setPracticeLocation] = useState<PracticeLocation[]>([]);
  const [newPracticeLocationID, setNewPracticeLocationID] = useState("");

  const onPracticeLocationAction = async (event: any, ...args: Array<string | null>) => {
    const actionType = args[0];
    const practiceLocationArg = args[1];
    const actionHasConfirmDialogStep = args[2];
    let data = {};

    if (actionHasConfirmDialogStep === "NEEDS_CONFIRMATION") {
      // eslint-disable-next-line no-restricted-globals
      if (confirm(translate('are_you_sure_remove_practice_location'))) {
        onPracticeLocationAction(event, actionType, practiceLocationArg);
      }
    }
    else if(actionType === "SET") {
      if (!practiceLocationArg) {
        return;
      }

      if (!practiceLocation?.find((practiceLocationSelected) => practiceLocationSelected.kinshipId === practiceLocationArg)) {
        return;
      }

      data = {
        preferredLocation: practiceLocationArg
      }
    } else if (actionType === "CLEAR") {
      data = {
        preferredLocation: "NONE"
      }
    }

    await updatePetProfile(pet, data);

    onAction(data);
  };

  useEffect(() => {
    const fetchPracticeLocation = async () => {
      const partnerInfo = getLinnaeusPracticeGroup(pet?.ownersProfileExtras) as PracticeGroup;
      console.log(
        "PracticeLocations->partnerInfo =>", partnerInfo, 
        "partnerCode =>", partnerInfo?.partnerCode
      );
      
      // const data  = await getVeterinarySpecialists(
      //   partnerInfo?.partnerCode || '',
      //   partnerInfo?.kinshipId || ''
      // );

      const data  = await getVeterinarySpecialists(
        '',
        ''
      );

      const practiceLocationData = data.data as Array<PracticeLocation>;

      setPracticeLocation(practiceLocationData);

      console.log("preferredLocation =>", pet?.preferredLocation);
      console.log("practiceLocationData =>", practiceLocationData);

      const alreadySelectedPracticeLocation = practiceLocationData?.find((practiceLocationSelected) => practiceLocationSelected.kinshipId === pet?.preferredLocation);

      console.log("alreadySelectedPracticeLocation =>", alreadySelectedPracticeLocation);

      if (alreadySelectedPracticeLocation) {
        setSelectedPracticeLocation(alreadySelectedPracticeLocation);
      }
    }

    fetchPracticeLocation();
  }, [customFields, newPracticeLocationID, pet?.ownersProfileExtras, pet?.preferredLocation]);

  const PracticeLocationRender = practiceLocation.map((menuItem) => (
    <MenuItem key={`${menuItem.email} - ${menuItem.kinshipId}`} value={menuItem.kinshipId}>
      <Box>
        <Typography variant="subtitle1" fontWeight="bold">
          {menuItem?.name || ""}
        </Typography>
        <Typography variant="body2">{menuItem?.phone || ""}</Typography>
        <Typography variant="body2">{menuItem?.email || ""}</Typography>
        <Typography variant="body2">{menuItem?.address || ""}</Typography>
      </Box>
    </MenuItem>
  ));
  

  const mappedPracticeLocation = () => {
    if (selectedPracticeLocation) {
      return (
        <TableRow>
          <TableCell align="left" colSpan={2}>
            <Typography variant="subtitle1" fontWeight="bold">
              {selectedPracticeLocation?.name || ""}
            </Typography>
  
            <Box sx={{ mt: 1 }}>
              <Typography variant="body2">{selectedPracticeLocation?.phone || ""}</Typography>
              <Typography variant="body2">{selectedPracticeLocation?.email || ""}</Typography>
              <Typography variant="body2">{selectedPracticeLocation?.address || ""}</Typography>
            </Box>
  
            <Divider sx={{ my: 1 }} />
          </TableCell>
  
          <TableCell align="left" colSpan={2}>
            <Stack direction="row" spacing={1}>
              <IconButton
                color="primary"
                aria-label={translate("delete")}
                onClick={(event) =>
                  onPracticeLocationAction(event, "CLEAR", selectedPracticeLocation?.kinshipId || "", "NEEDS_CONFIRMATION")
                }
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          </TableCell>
        </TableRow>
      );
    }
  };
  

  return (
    <Box sx={{ mt: 2, p: 4, border: 1, borderColor: "gray", borderRadius: 5 }}>
    <Grid container sx={{ minWidth: 1200 }}>
      <Grid item xs={6}>
        <Typography
        variant="h4"
        component="h4"
        sx={{ mb: 2, fontWeight: "medium" }}
      >
        {translate("practiceLocation")}
      </Typography>
      <Table sx={{width: "max-content"}}>
      <TableBody>
        {mappedPracticeLocation()}
        <TableRow>
            <TableCell align="left" colSpan={2}>
              <Select
                displayEmpty
                value={newPracticeLocationID || selectedPracticeLocation?.kinshipId || ""}
                onChange={(e) => {
                  console.log("select->newPracticeLocationID =>", e.target.value);
                  setNewPracticeLocationID(e.target.value);
                }}
                sx={{ 
                  maxHeight: 300,
                  width: 'auto',
                  whiteSpace: 'normal'
                }}
              >
                {PracticeLocationRender}
              </Select>
            </TableCell>
            <TableCell align="left" colSpan={2}>
              <Stack direction="row" spacing={1}>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={(event) => onPracticeLocationAction(event, "SET", newPracticeLocationID)}
                >
                  {translate('set_practice_location')}
                </Button>
              </Stack>
            </TableCell>
        </TableRow>
      </TableBody>
    </Table>
    </Grid>
  </Grid>
</Box>
);
}
